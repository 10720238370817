import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']
  static classes = ['buttonDisabled']
  
  copyDown(event) {
    event.preventDefault()
    const copyButton = event.currentTarget
    const inputField = copyButton.dataset.previousFieldId && document.getElementById(copyButton.dataset.previousFieldId)
    const outputField = copyButton.dataset.nextFieldId && document.getElementById(copyButton.dataset.nextFieldId)
    
    if (!inputField || !outputField) { return }
    
    // copy
    outputField.value = inputField.value
  }
  
  updateCopyButton(event) {
    // outputField
    // previousCopyButton
    
    // if (outputField.value.trim() !== "") { previousCopyButton.classList.add(this.buttonDisabledClass }
  }
}
