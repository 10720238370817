import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['frame']
  
  initialize() {
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
  }
  
  connect() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  }
  
  disconnect() {
    document.removeEventListener('visibilitychange', this.handleVisibilityChange)
  }
  
  handleVisibilityChange() {
    if (document.hidden) { return }
    this.reloadFrames()
  }
  
  reloadFrames() {
    this.frameTargets.forEach((frame) => {
      if (frame.disabled) {
        frame.disabled = false
      } else {
        frame.reload()
      }
    })
  }
}
